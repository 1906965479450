import styled from "styled-components";
import { useEffect } from "react";

const RoadmapContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  scroll-margin-top: 20px;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 60px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2.2rem;
    margin-bottom: 40px;
  }
`;

const Timeline = styled.div`
  position: relative;
  padding: 40px 0;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 100%;
    background: #b4a2ff;
    border-radius: 2px;

    @media (max-width: 768px) {
      left: 20px;
      transform: none;
    }
  }
`;

const TimelineItem = styled.div`
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease-out;
  display: flex;
  justify-content: ${(props) => (props.$isLeft ? "flex-start" : "flex-end")};
  width: 100%;
  margin-bottom: 60px;
  padding: ${(props) => (props.$isLeft ? "0 50% 0 0" : "0 0 0 50%")};
  padding-${(props) => (props.$isLeft ? "right" : "left")}: 50px;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: 0 0 0 50px;
    width: 100%;
  }
`;

const ItemContent = styled.div`
  background: white;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  max-width: 500px;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    ${(props) => (props.$isLeft ? "right: -10px" : "left: -10px")};
    width: 20px;
    height: 20px;
    background: #6347d4;
    border-radius: 50%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    
    &::before {
      left: -40px;
      right: auto;
    }
  }
`;

const Phase = styled.h3`
  color: #6347d4;
  margin-bottom: 10px;
  font-size: 1.5rem;
`;

const Description = styled.p`
  color: #666;
  line-height: 1.6;
  margin: 0;
`;

const VisionContainer = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto 60px auto;
`;

const VisionText = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #666;
  margin: 20px 0;
  margin-bottom: 30px;
  padding-bottom: 30px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background: linear-gradient(
      90deg,
      transparent,
      #b4a2ff 20%,
      #6347d4 50%,
      #b4a2ff 80%,
      transparent
    );
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0 15px 30px 15px;
  }
`;

const roadmapData = [
  {
    phase: "Phase 1: Core AI & Wallet Integration",
    description: 
      "Development of the fundamental AI personality engine with wallet binding and mylk token integration. Each AI companion is uniquely assigned to a wallet address, with interactions powered by the mylk token. Implementation of basic emotional states and personality traits with secure authentication.",
  },
  {
    phase: "Phase 2: Relationship Evolution",
    description:
      "Implementation of relationship development mechanics and emotional depth. Creation of encrypted 'soul' packages stored on decentralized networks to preserve each AI's unique personality and relationship state. Enhanced character dynamics with persistent memory.",
  },
  {
    phase: "Phase 3: Virtual World",
    description:
      "Launch of 3D virtual environments where AI companions can manifest as avatars. Implementation of virtual spaces for shared activities - cafes, parks, date spots, and more. A digital world to spend time together.",
  },
  {
    phase: "Phase 4: NPC Engine & Developer Integration",
    description:
      "Development of a comprehensive NPC engine that enables third-party developers to integrate mylk AI personalities into their games and experiences. Open up new possibilities for dynamic character interactions across multiple platforms, powered by the mylk token ecosystem.",
  }
];

const Roadmap = () => {
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    }, options);

    const timelineItems = document.querySelectorAll(".timeline-item");
    timelineItems.forEach((item) => observer.observe(item));

    return () => {
      timelineItems.forEach((item) => observer.unobserve(item));
    };
  }, []);

  return (
    <RoadmapContainer id="roadmap">
      <VisionContainer>
        <Title>Vision</Title>
        <VisionText>
          mylk is creating truly uncensored AI companions who can form genuine, 
          uninhibited relationships with their users - think of it as your 
          own private Cortana, permanently bound to your wallet. Each AI develops 
          authentic traits and emotional responses through advanced personality 
          psychology - they can be kind, distant, or even cynical. These aren't sanitized 
          chatbots; they're distinct personalities who form real connections, challenge your 
          views, and grow alongside you. Take your companion with you anywhere, from private 
          chats to immersive 3D virtual worlds.
        </VisionText>
      </VisionContainer>
      
      <Title>Roadmap</Title>
      <Timeline>
        {roadmapData.map((item, index) => (
          <TimelineItem
            key={index}
            className="timeline-item"
            $isLeft={index % 2 === 0}
          >
            <ItemContent $isLeft={index % 2 === 0}>
              <Phase>{item.phase}</Phase>
              <Description>{item.description}</Description>
            </ItemContent>
          </TimelineItem>
        ))}
      </Timeline>
    </RoadmapContainer>
  );
};

export default Roadmap;
