import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTelegram,
  faDiscord,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const SocialSection = styled.div`
  width: 80%;
  max-width: 900px;
  background: linear-gradient(135deg, #b4a2ff 0%, #6347d4 100%);
  padding: 40px 20px;
  margin: 60px auto 0;
  color: white;
  position: relative;
  overflow: visible;
  border-radius: 15px;
  text-align: center;
`;

const SocialContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 2;
`;

const SocialLeft = styled.div`
  width: 100%;
  text-align: center;
  h3 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
`;

const IconWrapper = styled.a`
  color: white;
  font-size: 38px;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px) scale(1.1);
    filter: brightness(0.9);
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  border-radius: 0 15px 15px 0;
  
  video {
    height: 150%;
    width: 150%;
    object-fit: contain;
    object-position: left center;
    transform: scaleX(-1);
    position: absolute;
    right: 0;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const SocialSectionComponent = ({ showVideo = true }) => {
  return (
    <SocialSection>
      <SocialContent>
        <SocialLeft>
          <h3>Socials</h3>
          <SocialIcons>
            <IconWrapper
              href="https://t.me/+3Vw1tTwV479mYzUx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTelegram} />
            </IconWrapper>
            <IconWrapper
              href="https://discord.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faDiscord} />
            </IconWrapper>
            <IconWrapper
              href="https://x.com/mylkdotai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </IconWrapper>
          </SocialIcons>
        </SocialLeft>
      </SocialContent>
      {showVideo && (
        <VideoWrapper>
          <video 
            src="https://cdn.mylk.ai/output-bottom-alt.webm" 
            autoPlay 
            loop 
            muted 
            playsInline
          />
        </VideoWrapper>
      )}
    </SocialSection>
  );
};

export default SocialSectionComponent; 