import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const MAIN_GIRL =
  "https://res.cloudinary.com/dav8yugzm/image/upload/v1732066836/main-girl_e6ffuc.png";

const ChatTerminal = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    setMessages([...messages, { text: inputValue, isUser: true }]);
    setInputValue("");
  };

  return (
    <ChatContainer>
      <BackButton onClick={() => navigate("/")}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </BackButton>

      <ProfileSection>
        <ProfileImage src={MAIN_GIRL} alt="Aurora" />
        <ProfileInfo>
          <ProfileName>Aurora</ProfileName>
          <ProfileAge>30 years old</ProfileAge>
          <ProfileBio>
            A passionate and creative soul with a deep interest in art and
            technology. Aurora brings warmth and wisdom to every conversation,
            drawing from her diverse experiences and endless curiosity about the
            world.
          </ProfileBio>
        </ProfileInfo>
      </ProfileSection>

      <ChatWindow>
        <MessageList>
          {messages.map((message, index) => (
            <MessageWrapper key={index} $isUser={message.isUser}>
              {message.isUser && <SenderLabel>You</SenderLabel>}
              <MessageBubble $isUser={message.isUser}>
                {message.text}
              </MessageBubble>
            </MessageWrapper>
          ))}
        </MessageList>
      </ChatWindow>
      <InputForm onSubmit={handleSubmit}>
        <ChatInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type your message..."
        />
        <SendButton type="submit">Send</SendButton>
      </InputForm>
    </ChatContainer>
  );
};

export default ChatTerminal;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1a1a1a;
`;

const ChatWindow = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  justify-content: center;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #2a2a2a;
  }
  &::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 4px;
  }
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  max-width: 800px;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0;
`;

const SenderLabel = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const MessageBubble = styled.div`
  max-width: 85%;
  padding: 8px 0;
  border-radius: 12px;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  align-self: flex-start;
`;

const InputForm = styled.form`
  display: flex;
  gap: 12px;
  padding: 16px;
  background: transparent;
  max-width: 800px;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 20px;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 12px 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 15px;
  background: #2a2a2a;
  color: white;
  outline: none;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: #444;
  }

  &::placeholder {
    color: #888;
  }
`;

const SendButton = styled.button`
  padding: 12px 24px;
  background-color: #444;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #555;
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.2s;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #2a2a2a;
  }
`;

const ProfileSection = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileImage = styled.img`
  width: 240px;
  height: 315px;
  object-fit: cover;
  border-radius: 15px;
`;

const ProfileInfo = styled.div`
  padding: 15px;
  background: #2a2a2a;
  border-radius: 12px;
  color: white;
`;

const ProfileName = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
`;

const ProfileAge = styled.p`
  margin: 5px 0 15px;
  color: #888;
  font-size: 16px;
`;

const ProfileBio = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #ccc;
`;
