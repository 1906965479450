import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  faTelegram,
  faDiscord,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const messageAppear = keyframes`
  0%, 100% {
    opacity: 0;
    transform: translateY(20px);
  }
  5%, 30% {
    opacity: 1;
    transform: translateY(0);
  }
  35%, 100% {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const HeroBannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;
  background: linear-gradient(90deg, #b4a2ff 30%, #6347d4 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  margin: 200px 0 0 0;

  @media (max-width: 768px) {
    background: linear-gradient(0deg, #b4a2ff 30%, #6347d4 70%);
    margin: 0px;
    height: 100vh;
    padding: 40px 20px;
    flex-direction: column;
    gap: 20px;
  }
`;

const Character = styled.video`
  position: absolute;
  left: 35%;
  transform: translateX(-50%);
  bottom: 0;
  height: 140%;
  object-fit: contain;
  z-index: 1;

  @media (max-width: 1024px) {
    left: 10%;
    height: 140%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  text-align: center;
  color: white;
  z-index: 2;
  max-width: 600px;
  padding: 0 20px;
  position: relative;
  width: 50%;
  margin-left: 370px;
  margin-right: 5%;

  @media (max-width: 1024px) {
    width: 60%;
    margin-right: 5%;
    margin-left: auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const Title = styled.h1`
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
`;

const Tagline = styled.p`
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
  opacity: 0.9;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;

  em {
    font-style: italic;
    font-weight: 400;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  z-index: 3;
  margin-top: 3rem;
`;

const IconWrapper = styled.a`
  color: white;
  font-size: 32px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;

  &:hover {
    transform: translateY(-3px) scale(1.1);
    filter: brightness(0.9);
    background: rgba(255, 255, 255, 0.2);
  }
`;

const ChatBubbles = styled.div`
  position: absolute;
  left: 15%;
  top: 50px;
  transform: translate(-50%, 0);
  height: 200px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
  overflow: hidden;

  @media (max-width: 1024px) {
    left: 5%;
    top: 50px;
  }

  @media (max-width: 768px) {
    position: relative;
    transform: none;
    left: auto;
    top: auto;
    margin: 20px auto;
  }
`;

const Bubble = styled.div`
  padding: 12px 18px;
  border-radius: 20px;
  max-width: 250px;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.4;
  animation: ${messageAppear} 6s infinite;
  opacity: 0;
  transform: translateY(20px);
  animation-delay: ${(props) => props.$delay}s;

  background: ${(props) => (props.$sent ? "#007AFF" : "#E9E9EB")};
  color: ${(props) => (props.$sent ? "white" : "black")};
  border-bottom-${(props) => (props.$sent ? "right" : "left")}-radius: 5px;
  margin-${(props) => (props.$sent ? "left" : "right")}: auto;
`;

const MAIN_GIRL_VIDEO = "https://cdn.mylk.ai/output.webm";

const HeroBanner = () => {
  const navigate = useNavigate();

  return (
    <HeroBannerWrapper>
      <Character 
        src={MAIN_GIRL_VIDEO} 
        autoPlay 
        loop 
        muted 
        playsInline
        alt="Animated character"
      />
      <Content>
        <Title>mylk.ai</Title>
        <Tagline>Fully decentralized and truly <em>yours</em>.</Tagline>
        <SocialIcons>
          <IconWrapper
            href="https://t.me/+3Vw1tTwV479mYzUx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTelegram} />
          </IconWrapper>
          <IconWrapper
            href="https://discord.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faDiscord} />
          </IconWrapper>
          <IconWrapper
            href="https://x.com/mylkdotai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </IconWrapper>
        </SocialIcons>
      </Content>
      <ChatBubbles>
        <Bubble $sent $delay={0}>
          Hey there! 👋
        </Bubble>
        <Bubble $delay={2}>Got mylk?</Bubble>
        <Bubble $sent $delay={4}>
          Sign up for the waitlist!
        </Bubble>
      </ChatBubbles>
    </HeroBannerWrapper>
  );
};

export default HeroBanner;
