import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import HeroBanner from "./Components/HeroBanner/HeroBanner";
import Roadmap from "./Components/Roadmap/Roadmap";
import ChatTerminal from "./Components/ChatTerminal/ChatTerminal";
import SocialSectionComponent from './Components/Common/SocialSection'

function App() {
  return (
    <Router>
      <MainAppContainer>
        <Routes>
          <Route path="/" element={
            <>
              <HeroBanner />
              <Roadmap />
              <SocialSectionComponent />
            </>
          } />
          <Route path="/chat" element={<ChatTerminal />} />
        </Routes>
      </MainAppContainer>
    </Router>
  );
}

export default App;

const MainAppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  padding-bottom: 60px;
`;
